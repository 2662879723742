import { useState } from "react";
import { Form } from "react-bootstrap";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  return { values, setValues, handleInputChange };
};

export const ReusableForm = ({ className, children, onSubmit }) => {
  return (
    <Form className={className} onSubmit={onSubmit}>
      {children}
    </Form>
  );
};
