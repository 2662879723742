import React from "react";
import { Col, NavDropdown, NavLink, Navigate } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { GrLanguage } from "react-icons/gr";
import { FaCheck } from "react-icons/fa6";
import style from './LanguageSwitcher.module.css';


const LanguageSwitcher = ({position, drop, mobile})=>{
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  
  const changeLanguage = (lng) => {
    const newPath = `/${lng}${location.pathname.substring(3)}`; // Quitar el idioma actual y agregar el nuevo
    navigate(newPath, { replace: true });
    i18n.changeLanguage(lng);
  };

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
  ];

  return(
    <div className={`${position === 'footer' && style.langSwitcherContainerFooter} ${position === 'header' && style.langSwitcherContainerHeader} ${position === 'mobile' && style.langSwitcherContainerMobile}`}>
      <GrLanguage />
      <NavDropdown drop={drop} title={i18n.language == 'en' ? 'English' : 'Español'} className={`${style.langSwitcherDropdown} ${position === 'header' && style.links} ${position === 'mobile' && style.mobileLinks}` }>
        {/* {languages.filter(lang => lang.code !== i18n.language)
          .map(lang => (
            <NavDropdown.Item
              key={lang.code}
              as={NavLink}
              onClick={() => changeLanguage(lang.code)}
              className={style.langSwitcherDropdownItem}
            >
              {lang.label}
            </NavDropdown.Item>
          ))
      } */}
      {languages.map(lang => (
            <NavDropdown.Item
              key={lang.code}
              as={NavLink}
              onClick={() => changeLanguage(lang.code)}
              className={`${style.langSwitcherDropdownItem} ${position === 'header' && style.userOptions} ${position === 'mobile' && style.mobileDropdownLinks}`}
            >
              {lang.label} {lang.code === i18n.language && <FaCheck className={style.selectedLangCheck} /> }
            </NavDropdown.Item>
          ))
      }
      </NavDropdown>
    </div>
  )
}

export default LanguageSwitcher;