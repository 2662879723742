import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { sizes } from "../../utils";

const FilterBySize = ({ sizesSelected, handleChange }) => {
  const { t } = useTranslation();
  return (
    <Col className="mb-3 p-0" xs={6} sm={4} md={3} xl={2}>
      <DropdownBtn title={t("Filter by Size")} variant="secondary">
        {sizes.map((size, index) => {
          const transformedSize = size.toString().replace(/\./g, "_");

          return (
            <Dropdown.Item
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(`size${transformedSize}`);
              }}
            >
              <Form.Check
                type="switch"
                checked={sizesSelected[`size${transformedSize}`]}
                name={`size${size}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(`size${transformedSize}`);
                }}
                onChange={() => {}}
                label={
                  transformedSize.includes("_1")
                    ? `${transformedSize.split("_")[0]}" LINK`
                    : size === 160
                    ? size
                    : size === 260
                    ? size
                    : size === 350
                    ? size
                    : size === 360
                    ? size
                    : `${size}"`
                }
              />
            </Dropdown.Item>
          );
        })}
      </DropdownBtn>
    </Col>
  );
};

export default FilterBySize;
