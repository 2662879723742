import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import style from "./Filters.module.css";

const SizeOption = ({ size }) => {
  const formattedSize = size.toString().replace(/\./g, "_");
  const baseSize = formattedSize.split("_")[0];
  const sizeLabel = size.toString().includes(".")
    ? `${baseSize}" LINK`
    : size > 100
    ? `${baseSize}`
    : `${size}"`;

  return (
    <Col xs={"auto"}>
      <small className={style.rowData}>{sizeLabel}</small>
    </Col>
  );
};

const BalloonOption = memo(({ option, sizes }) => {
  const { t } = useTranslation();
  return (
    <Row className={style.balloonOptionRow}>
      <Col xs={5} md={4} lg={3}>
        <h4 className={style.rowTitle}>{t("SWATCH NAME")}</h4>
        <small className={style.rowData}>{option.displayName}</small>
      </Col>
      <Col xs={7} md={4} lg={3}>
        <h4 className={style.rowTitle}>{t("BRAND & COLOR NAME")}</h4>
        {option.combined ? (
          <>
            <h5 className={style.rowSubtitle}>{t("OUTSIDE/INSIDE")}</h5>
            <div>
              <small className={style.rowData}>
                {option.brand.split("/")[0] === "Ellies"
                  ? "Ellie's"
                  : option.brand.split("/")[0]}{" "}
                - {option.outsideColor} /{" "}
                {option.brand.split("/")[1] === "Ellies"
                  ? "Ellie's"
                  : option.brand.split("/")[1]}{" "}
                - {option.insideColor}
              </small>
            </div>
          </>
        ) : (
          option.brand && (
            <small className={style.rowData}>
              {option.brand === "Ellies" ? "Ellie's" : option.brand} -{" "}
              {option.name}
            </small>
          )
        )}
      </Col>
      <Col xs={12} md={4} lg={3}>
        <h4 className={style.rowTitle}>{t("AVAILABLE SIZES")}</h4>
        <Row>
          {sizes.map(
            (size, index) =>
              option[`size${size.toString().replace(/\./g, "_")}`] && (
                <SizeOption key={index} size={size} />
              )
          )}
        </Row>
      </Col>
    </Row>
  );
});

export default BalloonOption;
