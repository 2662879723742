import React from "react";
import { Carousel } from "react-bootstrap";

const Slider = ({
  index,
  controls,
  indicators,
  interval,
  onSelect,
  children,
}) => {
  return (
    <Carousel
      controls={controls}
      indicators={indicators}
      activeIndex={index}
      onSelect={onSelect}
      interval={interval}
    >
      {children}
    </Carousel>
  );
};

export default Slider;
