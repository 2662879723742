import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { categories } from "../../utils";

const FilterByCategory = ({ categoriesSelected, handleChange }) => {
  const { t } = useTranslation();
  return (
    <Col className="mb-3 p-0" xs={6} sm={4} md={3} xl={2}>
      <DropdownBtn title={t("Filter by Finish")} variant="secondary">
        {categories.map((category, index) => (
          <Dropdown.Item
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(category);
            }}
          >
            <Form.Check
              type="switch"
              checked={categoriesSelected[category]}
              name={category}
              label={category}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(category);
              }}
              onChange={() => {}}
            />
          </Dropdown.Item>
        ))}
      </DropdownBtn>
    </Col>
  );
};

export default FilterByCategory;
