import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context";
import { NavLinkWithLocale, Footer, Scroller } from "../../components";
import { useScreenSize } from "../../utils";
import style from "./Home.module.css";

const Home = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const handleSubscribe = async () => {
    navigate("/createAccount");
    /* axios
      .post(
        "https://vps-3348494-x.dattaweb.com:5455/create-checkout-session",
        {
          lookup_key: "color_match",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log(error);
      }); */
  };

  return (
    <Container className={`p-0 m-0 ${style.homeContainer}`} fluid>
      <Row
        className={`px-0 py-4 m-0 gap-5 gap-md-0 justify-content-around align-items-center ${style.homeSec1}`}
      >
        <Col
          xs={{ order: "last", span: 11 }}
          sm={9}
          md={{ order: "first", span: 6 }}
          lg={6}
          className={style.heroImgContainer}
        >
          <Image
            width="85%"
            src={require("../../assets/balloon-color-match-homepage-hero.gif")}
          />
        </Col>
        <Col
          xs={{ order: "first", span: 12 }}
          md={{ order: "last", span: 6 }}
          lg={4}
          className={style.homeIntro}
        >
          <h1 className={style.homeTitle}>
            {t("Find your balloon match in seconds.")}
          </h1>
          <p className={style.homeSubtitle}>{t("homeSubtitle")}</p>
          {!user && (
            <NavLinkWithLocale to="/createAccount">
              <Button size="lg" className={style.homeBtn}>
                {t("SIGN UP FREE")}
              </Button>
            </NavLinkWithLocale>
          )}
        </Col>
      </Row>
      <Row
        className={`m-0 justify-content-center align-items-center ${style.homeSec3}`}
      >
        <h2 className={style.homeSecTitle}>{t("Brands We Feature")}</h2>
        <Scroller />
      </Row>
      <Row
        className={`mx-0 justify-content-center row-gap-3 row-gap-md-5 ${style.homeSec2}`}
      >
        <h2 className={style.homeSecTitle}>
          {t("2 Ways Balloon Color Match")}
          <br /> {t("Saves You Time and Elevates Your Business")}
        </h2>
        <Row
          className={`p-0 m-0 gap-5 align-items-center justify-content-center ${style.sec2first}`}
        >
          <Col xs={{ order: "last" }} md={5}>
            <Image
              src={require("../../assets/homepage-sourcing.png")}
              width="100%"
            />
          </Col>
          <Col
            xs={{ order: "first" }}
            md={{ order: "last", span: 5 }}
            lg={{ offset: 1, span: 4 }}
          >
            <h3 className={style.homeSecSubtitle}>
              {t("Quick Balloon Finder")}
            </h3>
            <p className={style.homeSecText}>{t("quickBalloonFinderTxt")}</p>
          </Col>
        </Row>
        <Row
          className={`p-0 m-0 gap-5 align-items-center justify-content-center ${style.sec2last}`}
        >
          <Col md={5} lg={{ span: 4 }}>
            <h3 className={style.homeSecSubtitle}>
              {t("Effortless Palette Builder")}
            </h3>
            <p className={style.homeSecText}>{t("effortesslyTxt")}</p>
          </Col>
          <Col md={5} lg={{ span: 5 }}>
            <Image
              src={require("../../assets/barbie-palette.png")}
              width="100%"
            />
          </Col>
        </Row>
      </Row>
      {!user && (
        <Row
          className={`m-0 align-items-center justify-content-around ${style.homeSecCta}`}
        >
          <Col lg={5} className={style.ctaIntro}>
            <h1 className={style.ctaTitle}>{t("Early Access")}</h1>
            <h5 className={style.ctaSubtitle}>{t("earlyAccessCTA")}</h5>
          </Col>
          <Col xs={12} sm={8} md={6} lg={4} xl={3} className={style.homeIntro}>
            <Button
              variant="light"
              size="lg"
              className={style.ctaBtn}
              onClick={handleSubscribe}
            >
              {t("SIGN UP FREE")}
            </Button>
            <p className={style.ctaInfo}>{t("No credit card required.")}</p>
          </Col>
        </Row>
      )}
      <Footer />
    </Container>
  );
};

export default Home;
