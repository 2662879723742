import { useState } from "react";

const useAddBtnModalState = () => {
  const [modalState, setModalState] = useState({
    balloon: false,
    foilShape: false,
    foilNumberLetter: false,
    confetti: false,
    tassel: false,
    backdropPanel: false,
    floralGreenery: false,
    custom: false,
  });

  /* const showModal = (type) => setModalState({ ...modalState, [type]: true }); */

  const showModal = (type) => {
    const updatedState = Object.fromEntries(
      Object.keys(modalState).map((key) => [key, key === type])
    );
    setModalState(updatedState);
  };

  const closeModal = () =>
    setModalState({
      balloon: false,
      foilShape: false,
      foilNumberLetter: false,
      confetti: false,
      tassel: false,
      backdropPanel: false,
      floralGreenery: false,
      custom: false,
    });

  return { modalState, showModal, closeModal };

};

export default useAddBtnModalState;
