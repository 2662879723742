import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Image } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { AutoCompleteFilters, Inputs } from "../index";
import style from "./Add.module.css";

export const AddElementModalContent = ({
  title,
  options,
  loading,
  selected,
  setSelected,
  placeholder,
  renderOption,
}) => (
  <AutoCompleteFilters
    title={title}
    options={options}
    isLoading={loading}
    selected={selected}
    setSelected={setSelected}
    placeholder={placeholder}
    renderOption={renderOption}
  />
);

export const CustomModalContent = ({
  customImg,
  handleDrop,
  setCustomImage,
}) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {customImg.imageURL ? (
        <Row className="align-items-center">
          <Col sm={3}>
            <Image width={100} src={customImg.imageURL} />
          </Col>
          <Col>
            <Row className="gap-2">
              <Col sm={12}>
                <Dropzone
                  onDrop={handleDrop}
                  accept={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/gif": [],
                    "image/webp": [],
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Col
                      {...getRootProps()}
                      className={style.dropzone}
                      style={{
                        height: customImg.imageURL && "40px",
                        fontSize: "12px",
                      }}
                    >
                      <input {...getInputProps()} />
                      <p>
                        {t(
                          "Drag 'n' drop some files here, or click to select files"
                        )}
                      </p>
                    </Col>
                  )}
                </Dropzone>
              </Col>
              <Col sm={12}>
                <Inputs.FormControl
                  label="Title Name"
                  placeholder="Enter title name"
                  onChange={(e) =>
                    setCustomImage({
                      ...customImg,
                      name: e.target.value,
                      id: uuidv4(),
                      hexColor: "#",
                    })
                  }
                  value={customImg.name || ""}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Dropzone
          onDrop={handleDrop}
          accept={{
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
            "image/webp": [],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Col {...getRootProps()} className={style.dropzone}>
              <input {...getInputProps()} />
              <p>
                {t("Drag 'n' drop some files here, or click to select files")}
              </p>
              <p className={style.helperTextSubtitle}>
                {t("Accepts")} .jpg, .jpeg, .png, .webp and .gif file types
                (Max. 5MB)
              </p>
            </Col>
          )}
        </Dropzone>
      )}
    </Fragment>
  );
};
