import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  setDoc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  getFirestore,
  doc,
  deleteDoc,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

const fetchPalette = async (userId, order) => {
  const q = query(
    collection(db, "savedPalettes"),
    where("user", "==", userId),
    orderBy("createdAt", order)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const usePalette = (userId, order) => {
  return useQuery(
    ["savedPalettes", userId, order],
    () => fetchPalette(userId, order),
    {
      enabled: !!userId,
    }
  );
};

const fetchPaletteById = async (docId, shared) => {
  const docRef = doc(db, shared ? "sharedPalettes" : "savedPalettes", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // paleta existe
    const paletteData = docSnap.data();
    return paletteData;
  } else {
    // paleta no existe
    return null;
  }
};

export const usePaletteById = (docId, shared) => {
  return useQuery([shared ? "sharedPalettes" : "savedPalettes", docId], () =>
    fetchPaletteById(docId, shared)
  );
};

const addPalette = async (palette, shared, id) => {
  const collectionName = shared ? "sharedPalettes" : "savedPalettes";
  await setDoc(doc(db, collectionName, id), palette);
  return id;
};

export const useAddPalette = (shared) => {
  const queryClient = useQueryClient();
  return useMutation(({ palette, id }) => addPalette(palette, shared, id), {
    onSuccess: (id) => {
      //console.log("El ID del documento agregado es: ", id);
      if (shared) {
        queryClient.invalidateQueries("sharedPalettes");
      } else {
        queryClient.invalidateQueries("savedPalettes");
      }
    },
  });
};

const updatePalette = async ({ palette, paletteId }) => {
  try {
    return await setDoc(doc(db, "savedPalettes", paletteId), palette);
  } catch (error) {
    console.error("Error updating palette:", error);
    throw new Error("Could not update palette");
  }
};

export const useUpdatePalette = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ palette, paletteId }) => updatePalette({ palette, paletteId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("savedPalettes");
      },
    }
  );
};

const deletePalette = async (user, paletteName) => {
  const q = query(
    collection(db, "savedPalettes"),
    where("user", "==", user),
    where("name", "==", paletteName)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const docSnapshot = querySnapshot.docs[0];
    await deleteDoc(doc(db, "savedPalettes", docSnapshot.id));
  }
};

export const useDeletePalette = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ user, paletteName }) => deletePalette(user, paletteName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("savedPalettes");
      },
      onError: (error) => {
        console.error("Error deleting palette:", error);
      },
    }
  );
};
