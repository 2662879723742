import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GoDot, GoDotFill } from "react-icons/go";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useAppContext } from "../../context";
import { Toaster } from "sonner";
import {
  Col,
  Container,
  Row,
  Spinner,
  Image,
  Carousel,
  Button,
} from "react-bootstrap";
import {
  CardsCommands,
  ColorCard,
  Share,
  Slider,
  ViewDetailsRow,
} from "../../components";
import { usePaletteById, sizes } from "../../utils";
import style from "./SharedPalette.module.css";
import { useTranslation } from "react-i18next";

const SliderImages = ({ images, index, setIndex, onSelect }) => (
  <Col xs={10} md={6} lg={3} xl={4}>
    <Slider
      index={index}
      onSelect={onSelect}
      controls={false}
      indicators={false}
      interval={null}
    >
      {images.map((image, idx) => (
        <Carousel.Item key={idx}>
          <Image width="100%" src={image.imageURL} />
        </Carousel.Item>
      ))}
    </Slider>
    {images.length > 1 && (
      <Row className="justify-content-center align-items-center my-4">
        <Col xs="auto">
          <Button
            variant="dark"
            onClick={() =>
              setIndex((prevIndex) =>
                prevIndex === 0 ? images.length - 1 : prevIndex - 1
              )
            }
            disabled={index === 0}
            size="sm"
            className={style.actionBtn}
          >
            <GrPrevious />
          </Button>
        </Col>
        <Col xs="auto">
          {images.map((_, i) =>
            index === i ? (
              <GoDotFill key={i} />
            ) : (
              <GoDot
                key={i}
                onClick={() => onSelect(i)}
                style={{ cursor: "pointer" }}
              />
            )
          )}
        </Col>
        <Col xs="auto">
          <Button
            variant="dark"
            onClick={() =>
              setIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
              )
            }
            disabled={images.length - 1 === index}
            size="sm"
            className={style.actionBtn}
          >
            <GrNext />
          </Button>
        </Col>
      </Row>
    )}
  </Col>
);

const SharedPalette = () => {
  const { paletteId } = useParams();
  const {
    data: palette,
    isLoading,
    isSuccess,
  } = usePaletteById(paletteId, true);
  const [index, setIndex] = useState(0);
  const [overlay, setOverlay] = useState({
    save: false,
    share: false,
  });
  const [paletteDate, setPaletteDate] = useState(null);
  const [paletteExpiresDate, setPaletteExpiresDate] = useState(null);

  const { shareMode, setShareMode } = useAppContext();

  const {t} = useTranslation();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (palette?.type === "client") {
      setShareMode("client");
    } else if (palette?.type === "internal") {
      setShareMode("internal");
    }
  }, [palette, shareMode]);

  useEffect(()=>{
    if(palette){
      const date = new Date(palette.createdAt.seconds * 1000);
      const options = {
        hour12: false,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      setPaletteDate(date.toLocaleString("en-US", options));

      const expiresDate = new Date(date);
      expiresDate.setDate(date.getDate() + 7);
      setPaletteExpiresDate(expiresDate.toLocaleString("en-US", options));

    }
  }, [palette])

  const clientPalette = (
    <Row className={`${style.sharedPaletteTypeRowContainer} mt-4`}>
      {palette?.showImage && (
        <SliderImages
          images={palette.images}
          index={index}
          setIndex={setIndex}
          onSelect={handleSelect}
        />
      )}
      <Col md={6} xl={8} className={style.colorCardsContainer}>
        <Row id="cardsContainer" xs={2} md={3} lg={5} className="row-gap-4">
          {palette?.colors.map((color, index) => (
            <ColorCard key={index} color={color} share={true} />
          ))}
        </Row>
      </Col>
    </Row>
  );

  const internalPalette = (
    <Row className={`${style.sharedPaletteTypeRowContainer} mt-4`}>
      {palette?.showImage && (
        <SliderImages
          images={palette.images}
          index={index}
          setIndex={setIndex}
          onSelect={handleSelect}
        />
      )}
      {/*<Col xs={12} md={8} lg={7} className={`p-0 ${style.infoContainer}`}>
         {palette?.colors.map((color, index) => (
          <ViewDetailsRow
            key={color.id}
            item={color}
            index={index}
            sizes={sizes}
            buttons={false}
            shared={true}
          />
        ))} 
      </Col>*/}

      <Col md={6} xl={8} className={style.colorCardsContainer}>
        <Row id="cardsContainer" xs={2} md={3} lg={5} className="row-gap-4">
          {palette?.colors.map((color, index) => (
            <ColorCard key={index} color={color} share={true} internal={true} />
          ))}
        </Row>
      </Col>
    </Row>
  );

  const returnPalette = () => {
    if (!palette) return null;

    return palette.type === "client"
      ? clientPalette
      : palette.type === "internal" && internalPalette;
  };

  return (
    <>
      {!isLoading && palette && isSuccess && (
        <Container fluid={true} className={style.sharedPaletteToolBarContainer}>
          <Container>
            <Row>
              <Col>
                <Share
                  sharedPalette={true}
                  sharedPaletteObj={palette}
                  overlay={overlay.share}
                  setOverlay={setOverlay}
                />
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      <Container className="mb-5">
        {isLoading ? (
          <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <Spinner animation="grow" size="sm" variant="dark" />
            </Col>
          </Row>
        ) : isSuccess && !palette ? (
          <Row className="justify-content-center align-items-center">
            <Col xs="auto">
              <p className={style.expiredMessage}>
                This link has expired or the palette may no longer exist.<br/>Please verify the URL or return to the previous page
              </p>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="align-items-center mt-4">
              <Col>
                <h1 className={style.sharedPaletteTitle}>{palette.paletteName && palette.paletteName}</h1>
                <p className={`${style.paletteUserInfo} ${style.paletteSharedByInfo}`}>
                  {t("Created by:")} {palette.user.name} {palette.user.company ? " - " + palette.user.company + " ": null}{" "}
                   {palette.clientName && "| For: " + palette.clientName}
                </p>
                <p className={style.paletteUserInfo}>
                  {/* Created on: {paletteDate && paletteDate}.  */}{t("Link expires on:")} {paletteExpiresDate && paletteExpiresDate}.
                </p>
              </Col>
            </Row>
            {returnPalette()}
          </>
        )}
        <Toaster richColors position="bottom-center" />
      </Container>
    </>
  );
};

export default SharedPalette;