import React, { useCallback, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useAppContext } from "../../context";
import {
  Alert,
  useScreenSize,
  useAddImages,
  useUpdateImageUploadedCounter,
} from "../../utils";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import { app } from "../../firebase";
import style from "./DropZone.module.css";

const storage = getStorage(app);

const DropZone = ({ btn }) => {
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const { images, setImages, toolMode, setToolMode, user } = useAppContext();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const screenSize = useScreenSize();
  const updateImageUploadedCounter = useUpdateImageUploadedCounter();

  const addImages = useAddImages();
  const { t } = useTranslation();

  useEffect(() => {
    if (images.length > 3) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [images]);

  const uploadingImage = (image) => {
    const storageRef = ref(
      storage,
      `imagesUploadedByUser/${user.name}-${user.lastName}_${image.name}`
    );
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_change",
        (snapshot) => {
          setProgress({
            ...snapshot,
            totalBytes: snapshot.totalBytes,
            complete: snapshot.bytesTransferred,
          });
        },
        (error) => {
          console.error("Error al subir la imagen:", error);
          reject(error);
        },
        async () => {
          console.log("Imagen subida correctamente!");
          try {
            const downloadURL = await getDownloadURL(storageRef);
            resolve({ downloadURL, path: storageRef.fullPath });
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      /* const file = acceptedFiles[0]; */
      if (acceptedFiles.length > 4) {
        Alert.error("Max of 4 images");
        return;
      }

      updateImageUploadedCounter.mutate({
        email: user.email,
        imageCount: acceptedFiles.length,
      });

      if (
        user.email !== process.env.REACT_APP_ADMIN1_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN2_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN3_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN4_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN5_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN6_EMAIL
      ) {
        const uploadPromises = acceptedFiles.map((file) =>
          uploadingImage(file)
        );

        acceptedFiles.forEach((file, index) => {
          if (file) {
            setImages((prevImages) => [...prevImages, file]);

            uploadPromises[index]
              .then(({ downloadURL, path }) => {
                // Creamos un objeto imageToSend para cada imagen
                const imageToSend = {
                  imageURL: downloadURL,
                  path: path,
                  user: user.email,
                  uploadedAt: serverTimestamp(),
                };

                console.log({ imageToSend });
                // Enviamos cada imagen como un registro independiente a Firestore
                addImages.mutate(imageToSend);
              })
              .catch((error) => {
                console.error("Error subiendo la imagen:", error);
              });
          }
        });
      } else {
        acceptedFiles.forEach((file) => {
          setImages((prevImages) => [...prevImages, file]);
        });
      }

      if (toolMode !== "palette") {
        setToolMode("tool");
      }
    },
    [setImages, setToolMode, user]
  );

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-invalid-type") {
            Alert.error(
              "Format not accepted. Accepts .jpg, .jpeg, .png, .webp and .gif file types."
            );
          }
        });
      });

      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  return (
    <Dropzone
      onDrop={handleDrop}
      disabled={images.length > 3 || !user}
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/gif": [],
        "image/webp": [],
      }}
      multiple={true}
    >
      {({ getRootProps, getInputProps }) => (
        <Row
          xs={12}
          {...getRootProps()}
          className={`${
            images.length === 0 && !btn
              ? style.dropzone
              : style.imageUploadedContainer
          } justify-content-center align-items-center`}
          style={
            images.length === 0 && !btn
              ? {
                  backgroundColor: "#ebedef",
                  display: toolMode === "palette" && "none",
                }
              : null
          }
        >
          <input {...getInputProps()} />
          {/* {btn && ( */}
          <Col xs="auto">
            {/* {toolMode === "choose" ? (
                <Button variant="secondary" className={style.chooseBtn}>
                  UPLOAD IMAGE
                </Button>
              ) : ( */}
            {btnDisabled ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={style.infoTooltip}>
                    {t("Max of 4 images reached")}
                  </Tooltip>
                }
              >
                <div>
                  <Button
                    variant="secondary"
                    size="sm"
                    className={style.actionBtn}
                    disabled={images.length > 3}
                  >
                    <MdOutlineLibraryAdd /> {t("Add image")}
                  </Button>
                </div>
              </OverlayTrigger>
            ) : (
              !(images.length === 0) && (
                <div>
                  <Button
                    variant="secondary"
                    size="sm"
                    className={style.actionBtn}
                    disabled={
                      images.length > 3 ||
                      (toolMode === "tool" && images.length === 0)
                        ? true
                        : false
                    }
                  >
                    <MdOutlineLibraryAdd /> {t("Add image")}
                  </Button>
                </div>
              )
            )}
          </Col>
          {/* )} */}
          {!btn && images.length === 0 && (
            <Col xs="auto" className={style.helperTxtContainer}>
              <p className={`${style.helperTxtTitle} mt-3`}>
                {t("Upload")} {screenSize >= 992 && t("or drag ")}{" "}
                {t("image to start")}.
              </p>
              <p className={style.helperTextSubtitle}>
                {t("Accepts")} .jpg, .jpeg, .png, .webp and .gif file types
                (Max. 5MB)
              </p>
              <p
                className={`${style.helperTextSubtitle} ${style.clarification}`}
              >
                {t("Blurry or dark images may not provide the best results.")}
              </p>
              <p
                className={`${style.helperTextSubtitle} ${style.clarification}`}
              >
                {t("Use high-quality, well-lit images for best results.")}
              </p>
              <Button
                className={`${style.uploadBtn} btn btn-light btn-md mt-4`}
              >
                {t("uploadImage")}
              </Button>
            </Col>
          )}
        </Row>
      )}
    </Dropzone>
  );
};

export default DropZone;
