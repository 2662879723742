import React, { memo } from "react";
import { Col, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { brands } from "../../utils";
import { useTranslation } from "react-i18next";

const FilterByBrand = ({ brandsSelected, handleChange }) => {
  const { t } = useTranslation();
  return (
    <Col className="mb-3 p-0" xs={6} sm={4} md={3} xl={2}>
      <DropdownBtn title={t("Filter by Brand")} variant="secondary">
        {brands.map((brand) => (
          <Dropdown.Item
            key={brand}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(brand);
            }}
          >
            <Form.Check
              type="switch"
              checked={brandsSelected[brand]}
              name={brand}
              label={brand === "Ellies" ? "Ellie's" : brand}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(brand);
              }}
              onChange={() => {}}
            />
          </Dropdown.Item>
        ))}
      </DropdownBtn>
    </Col>
  );
};

export default memo(FilterByBrand);
