import { useState, useMemo, useCallback } from "react";
import { categories } from "../index";

const useFilterByCategory = (data) => {
  const [categoriesSelected, setCategoriesSelected] = useState(() =>
    Object.fromEntries(categories.map((category) => [category, true]))
  );

  const handleCategoryChange = useCallback((category) => {
    setCategoriesSelected((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  }, []);

  const filteredCategories = useMemo(() => {
    const categoriesToExclude = new Set(
      Object.keys(categoriesSelected).filter(
        (category) => !categoriesSelected[category]
      )
    );
    return data?.filter((balloon) => {
      return !categoriesToExclude.has(balloon.category);
    });
  }, [categoriesSelected, data]);

  return {
    categoriesSelected,
    handleCategoryChange,
    filteredCategories,
  };
};

export default useFilterByCategory;
