import { useMutation, useQueryClient } from "react-query";
import { updateDoc, getFirestore, doc } from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

const updateVisitCounter = async (email) => {
  await updateDoc(doc(db, "usuarios", email), {
    firstToolVisit: false,
  });
};

export const useUpdateVisitCounter = () => {
  const queryClient = useQueryClient();
  return useMutation(updateVisitCounter, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("usuarios");
    },
  });
};
