import React from "react";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import poppinsFont from "../../assets/Poppins-Medium.ttf";

Font.register({ family: "Poppins", src: poppinsFont });

const style = StyleSheet.create({
  page: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "14px",
  },
  imagesContainer: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: "5px",
  },
  image: {
    width: "90%",
    marginBottom: "15px",
  },
  cardsContainer: {
    flex: 8,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    marginBottom: "30px",
    border: "1px solid #e3e3e3",
  },
  cardRow: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #e3e3e3",
  },
  cardImg: {
    flex: 1,
    width: "80%",
    /* height: "auto", */
    padding: "5px",
  },
  dataTextContainer: {
    flex: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "10px",
    marginTop: "8px",
  },
  dataContainer: {
    flex: 5,
    display: "flex",
    flexDirection: "column",
  },
  dataTitle: {
    fontFamily: "Poppins",
    fontSize: "8px",
  },
  dataSubtitle: {
    fontFamily: "Poppins",
    fontSize: "6px",
  },
  dataText: {
    fontFamily: "Poppins",
    lineHeight: "1.5px",
    marginTop: "5px",
    fontSize: "7px",
    fontWeight: "800",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "10px",
  },
});

const PDFInternal = ({ colors, images, showImage, sizes, sharedPalette }) => {
  return (
    <Document>
      <Page style={style.page} wrap={false}>
        <View style={showImage && style.body}>
          {showImage && (
            <View style={style.imagesContainer}>
              {images.map((img, index) => (
                <Image key={index} src={sharedPalette ? img.imageURL : img} style={style.image} />
              ))}
            </View>
          )}
          <View style={style.cardsContainer}>
            {colors.length > 0 &&
              colors.map((card, index) => {
                return (
                  <View key={index} style={style.cardRow}>
                    <Image
                      src={card.image ? card.image[0].imageURL : card.imageURL}
                      style={style.cardImg}
                    />
                    <View style={style.dataTextContainer}>
                      <View style={style.dataContainer}>
                        <Text style={style.dataTitle}>BRAND & COLOR NAME</Text>
                        {card.combined ? (
                          <>
                            <Text style={style.dataSubtitle}>
                              OUTSIDE/INSIDE
                            </Text>
                            <Text style={style.dataText}>
                              {card.brand.split("/")[0]} - {card.outsideColor} /{" "}
                              {card.brand.split("/")[1]} - {card.insideColor}
                            </Text>
                          </>
                        ) : (
                          <Text style={style.dataText}>
                            {card.brand} - {card.name}
                          </Text>
                        )}
                      </View>
                      {/* <View style={style.dataContainer}>
                        <Text style={style.dataTitle}>AVAILABLE SIZES</Text>
                        <View
                          style={{ flexDirection: "row", flexWrap: "wrap" }}
                        >
                          {sizes.map((size) => {
                            let formatedSize = size
                              .toString()
                              .replace(".", "_");
                            return (
                              card[`size${formatedSize}`] && (
                                <View
                                  key={formatedSize}
                                  style={{ marginRight: 10 }}
                                >
                                  <Text style={style.dataText}>
                                    {size === 6.1
                                      ? `6" LINK`
                                      : size === 9.1
                                      ? `9" LINK`
                                      : size === 12.1
                                      ? `12" LINK`
                                      : size === 13.1
                                      ? `13" LINK`
                                      : size === 660.1
                                      ? `660" LINK`
                                      : size === 160
                                      ? size
                                      : size === 260
                                      ? size
                                      : size === 350
                                      ? size
                                      : size === 360
                                      ? size
                                      : `${size}"`}
                                  </Text>
                                </View>
                              )
                            );
                          })}
                        </View>
                      </View> */}
                    </View>
                  </View>
                );
              })}
          </View>
        </View>
        <View style={style.footer}>
          <Text
            style={{
              color: "#c8c8c8",
              fontSize: "8px",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            Powered by Balloon Color Match
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PDFInternal;
