import React from "react";
import { Modal } from "react-bootstrap";
import style from "./ModalWindow.module.css";

const ModalWindow = ({
  show,
  onHide,
  centered,
  size,
  fullscreen,
  closeButton,
  title,
  header,
  body,
  footer,
  helpModal,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={centered}
      size={size}
      fullscreen={fullscreen}
      backdrop="static"
      style={helpModal && { borderColor: "#F16553" }}
    >
      {title || header ? (
        <Modal.Header className={style.header} closeButton={closeButton}>
          {title ? (
            <Modal.Title className={style.modalTitle}>{title}</Modal.Title>
          ) : (
            header
          )}
        </Modal.Header>
      ) : (
        closeButton && <Modal.Header closeButton={closeButton} />
      )}
      <Modal.Body
        style={
          helpModal && {
            backgroundColor: "#F16553",
            borderRadius: "0.5rem",
            borderColor: "#F16553",
          }
        }
      >
        {body}
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ModalWindow;
