export const privacyEn = [
  {
    text: `Balloon Color Match Privacy Policy
    Last updated: June 12, 2024

    We at Balloon Color Match (“Balloon Color Match,” “we,” “us,” or “our”) created this privacy policy (“Privacy Policy”) because your privacy is critically important to us. Our privacy policy will explain how personal information is collected, used and disclosed by Balloon Color Match with respect to your access and use of Balloon Color Match site, applications and services (“Balloon Color Match”).

    By accessing and using our Balloon Color Match, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use. If you do not agree to any of these terms, then please do not use the Balloon Color Match. Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.

    Information We Collect
    We do not collect any personal or confidential information from you unless you voluntarily provide it to us.
    If you would like to use Balloon Color Match, you will need to access the application from the site by creating an account. Upon accessing the Balloon Color Match, you will be required to provide information to create an account. Such information may include, without limitation, your first and last name, email address, company name, phone number, location and profile picture. In this Privacy Policy, we refer to this information as “Confidential Information.”
    We may use third-party analytics technology to track and collect anonymous traffic data about the use of the Balloon Color Match. This information includes information about the type of device you are using, device model, operating system, Balloon Color Match version, your IP addresses, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, what sections of the Balloon Color Match you visit, and similar information that will help us analyze and evaluate how the Balloon Color Match is used (“Usage Information”). The third-party technology providers may have access to your information only for the purposes of performing these tasks and on behalf of Balloon Color Match and under obligations similar to those in this Privacy Policy.
    We also collect information that we collect using “cookie” technology. Cookies are small packets of data that a website stores on your computer’s or device’s hard drive so that your computer will “remember” information about your visit. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to help us collect Usage Information and to enhance your experience using the Balloon Color Match. If you do not want us to place a cookie on your hard drive, you may be able to turn that feature off on your computer or device. Please consult your Internet browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the Balloon Color Match may not function properly.
    We do not collect personal information in this way, but if you’ve provided us with personal information we may associate that information with the information that is collected automatically. Automatic data collection may be performed on our behalf by our services providers.`,
  },
  {
    title: `How We Use Your Information`,
    text: `We will use the Confidential Information and Usage Information collected by the Balloon Color Match for the following purposes:
  · To respond to your requests and to provide you with the Balloon Color Match;
  · To respond to your inquiries and contact you about changes to the Balloon Color Match;
  · To send you notices (for example, in the form of e-mails, mailings, and the like)
  · For any other purposes disclosed at the time the information is collected or to which you consent; and
  · As otherwise specifically described in this Privacy Policy.
  We may also use your information to improve our Balloon Color Match, and may use other information to enable us to customize your user experience.
  We, like many businesses, sometimes hire other companies to perform certain business-related functions. Examples include mailing information, maintaining databases, hosting services, and processing payments. When we employ another company to perform a function of this nature, we provide them with the information that they need to perform their specific function, which may include Personal Information. We require such third parties to maintain the confidentiality of such information and use such information only as necessary to provide us with designated services.
  If we or all or substantially all of our assets are acquired, we expect that the information that we have collected, including Confidential Information, would be transferred along with our other business assets.
  We may disclose your Confidential Information and Usage Information to government authorities, and to other third parties when compelled to do so by government authorities, at our discretion, or otherwise as required by law, including but not limited to in response to court orders and subpoenas. We also may disclose your Confidential Information and Usage Information when we have reason to believe that someone is or may be causing injury to or interference with our rights or property, other users of this Balloon Color Match, or anyone else that could be harmed by such activities.

  How We Protect Your 𝐈𝐧𝐟𝐨𝐫𝐦𝐚𝐭𝐢𝐨𝐧
  We take commercially reasonable steps to protect the Confidential Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases, nor can we guarantee that the information you supply will not be intercepted while being transmitted to and from us over the Internet.

  Accessing and Modifying Account Information
  You may update the information that is stored in your user account by e-mailing us at 𝑠𝑢𝑝𝑝𝑜𝑟𝑡@𝑏𝑎𝑙𝑙𝑜𝑜𝑛𝑐𝑜𝑙𝑜𝑟𝑚𝑎𝑡𝑐ℎ.𝑐𝑜𝑚 .`,
  },
  {
    title: `Promotional Materials`,
    text: `If you wish to stop receiving promotional e-mails, you may do so by e-mailing us at 𝑠𝑢𝑝𝑝𝑜𝑟𝑡@𝑏𝑎𝑙𝑙𝑜𝑜𝑛𝑐𝑜𝑙𝑜𝑟𝑚𝑎𝑡𝑐ℎ.𝑐𝑜𝑚 . You may also choose to unsubscribe from our emails by following the instructions in the bottom of the email.`,
  },
  {
    title: `External Links`,
    text: `The Balloon Color Match web application will contain links to External Sites. We have no control over those other sites and applications, and this Privacy Policy does not apply to them. Please refer to the terms of use and privacy policies of the External Sites for more information.`,
  },
  {
    title: `Important Notice to Non-U.S. Residents`,
    text: `The Balloon Color Match is operated in the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to the United States. By providing us with any information through the Balloon Color Match application, you consent to this transfer and our use of such information in accordance with this Privacy Policy.`,
  },
  {
    title: `Children`,
    text: `We do not knowingly collect personal information from users under the age of 18 through the Balloon Color Match application. If you are under 18, please do not give us any personal information. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children to never provide personal information through the application without their permission. If you have reason to believe that a user under the age of 18 has provided personal information to us, please contact us, and we will endeavor to delete that information from our databases.`,
  },
  {
    title: `California Residents`,
    text: `Under California Civil Code Section 1798.83, California residents who have an established business relationship with us may choose to opt out of our sharing their personal information with third parties for direct marketing purposes. If you are a California resident and (1) you wish to opt out; or (2) you wish to request certain information regarding our disclosure of your Personal Information to third parties for the direct marketing purposes, please contact us as set forth below.`,
  },
  {
    title: `Changes to This Privacy Policy`,
    text: `This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time, and will post any changes on the web site www.ballooncolormatch.com as soon as they go into effect. By accessing and/or using the Balloon Color Match application after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please refer back to this Privacy Policy on a regular basis.`,
  },
  {
    title: `How to Contact Us`,
    text: `If you have questions about this Privacy Policy, please e-mail us at 𝑠𝑢𝑝𝑝𝑜𝑟𝑡@𝑏𝑎𝑙𝑙𝑜𝑜𝑛𝑐𝑜𝑙𝑜𝑟𝑚𝑎𝑡𝑐ℎ.𝑐𝑜𝑚 , “PRIVACY POLICY” in the subject line.
    You also may write to Balloon Color Match at:
    840 N Lake Shore Dr, Unit 1902 Chicago, IL 60611`,
  },
  {
    title: `Force Majeure`,
    text: `We shall not be deemed accountable for any delays or inability to perform any aspect of the usage of Balloon Color Match application due to circumstances beyond our control. Such instances encompass, but are not restricted to, acts of nature, alterations in laws or regulations, embargoes, warfare, terrorist activities, civil unrest, fires, seismic events, nuclear incidents, floods, labor strikes, power outages, volcanic eruptions, exceptionally inclement weather conditions, and intrusions by hackers or third-party internet service providers.`,
  },
  {
    title: `Miscellaneous`,
    text: `In the event that any portion of these Terms of Use is held to be invalid or unenforceable, then such portion shall be construed in accordance with the applicable law as nearly as possible to reflect the original intentions of the parties, and the remainder of these Terms of Use shall remain in full force and effect. This section, the section entitled Intellectual Property, Indemnification, and the section entitled Disclaimer and Limitation of Liability shall survive the termination of these Terms of Use. You may not assign these Terms of Use. No waiver shall be effective unless in writing. Neither the course of conduct between parties nor trade practice shall act to modify any provision of these Terms of Use. These Terms of Use and any other agreements between the parties entered into through the Balloon Color Match application shall be governed by and construed in accordance with the laws of the State of Illinois. Except for proceedings commenced by Balloon Color Match to protect its intellectual property or confidential information which may be brought in any court of competent jurisdiction, the parties mutually agree that any and all disputes arising hereunder shall be resolved exclusively by state or federal courts located in Cook County, Illinois. These Terms of Use contain the entire agreement of the parties concerning the Balloon Color Match application, and supersede all existing agreements and all other oral, written or other communication between the parties concerning its subject matter.`,
  },
  {
    title: `How to Contact Balloon Color Match`,
    text: `If you have any questions or comments about these Terms of Use or the Balloon Color Match web application, please contact Balloon Color Match by email at support@ballooncolormatch.com.
    You also may write to Balloon Color Match at:
    840 N Lake Shore Dr, Unit 1902 Chicago, IL 60611`,
  },
];