import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Footer } from "../../components";
import style from "./ContactUs.module.css";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className={`p-0 m-0 ${style.contactUsContainer}`}>
      <Row className={`m-0 p-0 align-items-center`}>
        <Col xs={12} md={6} className={`px-4 my-4 ${style.contactUsIntro}`}>
          <Row className="my-4 gap-4 justify-content-center">
            <Col xs={9}>
              <h1 className={style.contactUsTitle}>{t("Contact Us")}</h1>
            </Col>
            <Col xs={9}>
              <p className={style.contactUsInfo}>{t("contactTxt")}</p>
            </Col>
          </Row>
          <Row className="my-2 justify-content-center">
            <Col xs={9}>
              <h5 className={style.contactUsSubtitle}>{t("Questions?")}</h5>
            </Col>
            <Col xs={9}>
              <p className={style.contactUsData}>
                support@ballooncolormatch.com
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={9}>
              <h5 className={style.contactUsSubtitle}>{t("Corporate")}</h5>
            </Col>
            <Col xs={9}>
              <p className={style.contactUsData}>
                1900 W Hubbard St, Chicago, IL 60622
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="p-0" xs={12} md={6}>
          <Image
            width="100%"
            src={require("../../assets/ballooncolormatch-contactus.jpg")}
          />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default ContactUs;
