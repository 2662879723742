import React from "react";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

const Dnd = ({ data, setData, children }) => {
  const getItemPosition = (id) => data.findIndex((item) => item.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    setData(() => {
      const originalPosition = getItemPosition(active.id);
      const newPosition = getItemPosition(over.id);

      return arrayMove(data, originalPosition, newPosition);
    });
  };

  // Inicializa los sensores
  const mouseSensor = useSensor(MouseSensor);

  const touchSensor = useSensor(TouchSensor);

  // Combina los sensores
  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={data} strategy={horizontalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default Dnd;
