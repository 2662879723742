import React, { useMemo } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import style from "../Form.module.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useTranslation } from "react-i18next";

const AutoCompleteFilters = ({
  title,
  optionalTitle,
  options,
  isLoading,
  selected,
  setSelected,
  placeholder,
  renderOption,
}) => {
  const { t } = useTranslation();
  const filters = useMemo(
    () => ["brand", "category", "name", "displayName", "colorCategories"],
    []
  );

  const handleAutocompleteChange = (selectedItems) => {
    setSelected(selectedItems);
  };

  const customFilterBy = (option, props) => {
    const filterText = props.text.toLowerCase();
    return filters.some((field) => {
      const value = option[field];
      if (Array.isArray(value)) {
        return value.some((item) => item.toLowerCase().includes(filterText));
      }
      return (
        typeof value === "string" && value.toLowerCase().includes(filterText)
      );
    });
  };

  return (
    <Form.Group>
      <Form.Label>
        {optionalTitle || `${t("You can add multiple")} ${title}`}
      </Form.Label>
      <Typeahead
        id="filtering-autocomplete"
        labelKey={(option) =>
          `${option.displayName ? option.displayName : option.name} ${
            option.displayName ? `- ${option.name}` : ""
          }`
        }
        multiple
        onChange={handleAutocompleteChange}
        options={options || []}
        placeholder={placeholder}
        selected={selected}
        isLoading={isLoading}
        filterBy={customFilterBy}
        disabled={isLoading}
        paginate
        paginationText="Load more results"
        maxResults={25}
        renderMenuItemChildren={(option) => (
          <Row className={`pb-3 align-items-center ${style.optionRow}`}>
            {(option.image || option.imageURL) && (
              <Col xs={2} lg={1}>
                <Image
                  src={
                    option.image ? option.image[0].imageURL : option.imageURL
                  }
                  style={{ aspectRatio: "4/5" }}
                  width="100%"
                  alt="balloonImg"
                />
              </Col>
            )}
            <Col xs={10} lg={11}>
              {renderOption(option)}
            </Col>
          </Row>
        )}
      />
    </Form.Group>
  );
};

export default AutoCompleteFilters;
