import React from "react";
import { Outlet } from "react-router-dom";
import { HeaderBar } from "../components";
import "../index.css";

const MainRoutes = () => {
  return (
    <>
      <HeaderBar />
      <div className="main">
        <Outlet />
      </div>
    </>
  );
};

export default MainRoutes;
