export const sizes = [5, 6, 7, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 23, 24, 26, 30, 31, 36, 40, 69, 160, 260, 350, 360, 6.1, 9.1, 11.1, 12.1, 13.1, 18.1, 660.1]; //6.1 = 6" LINK | DB: 6_1

export const categories = [
  "Standard",
  "Pearl",
  "Chrome",
  "Metallic",
  "Mirror",
  "Crystal",
  "Reflex",
  "Matte",
  "Neon",
  "Brilliant",
  "Shiny",
  "Satin",
  "Silk",
  "Pastel",
  "Luster", 
  "Pastel Rainbow",
  " Metallic & Pearl",
  "Smoothies",
  "Superglow",
];

export const brands = [
  "Qualatex",
  "Tuftex",
  "Kalisan",
  "Sempertex",
  "Gemar",
  "Prima",
  "Balloonia",
  "Ellies",
  "Decomex",
  "Elitex"
].sort();

export const accessoriesCategories = [
  "Foil Shape",
  "Foil Number & Letter",
  "Confetti",
  "Tassels",
  "Backdrop/Panel Color",
  "Floral & Greenery",
];

export const dropdownOptions = [
  "Latex Balloons",
  "Foil Shape Balloons",
  "Foil Number & Letter Balloons",
  "Confetti",
  "Tassels",
  "Backdrop/Panel Colors",
  "Greenery & Florals",
  "Custom Upload",
];

export const colorCategories = [
  { name: "Reds" },
  { name: "Pinks" },
  { name: "Oranges" },
  { name: "Yellows" },
  { name: "Greens" },
  { name: "Blues" },
  { name: "Purples" },
  { name: "Whites" },
  { name: "Blacks" },
  { name: "Greys" },
  { name: "Nudes, Browns & Neutrals" },
];

export const sponsors = [
  {
    name: "qualatex",
    image: require("../assets/sponsors/qualatex-logo.png"),
  },
  {
    name: "sempertex",
    image: require("../assets/sponsors/sempertex-logo.png"),
  },
  {
    name: "kalisan",
    image: require("../assets/sponsors/kalisan-logo.png"),
  },
  {
    name: "tuftex",
    image: require("../assets/sponsors/tuftex-logo.png"),
  },
  {
    name: "anagram",
    image: require("../assets/sponsors/anagram-logo-soon.png"),
  },
  {
    name: "gemar",
    image: require("../assets/sponsors/gemar-logo.png"),
  },
  {
    name: "balloonia",
    image: require("../assets/sponsors/balloonia-logo.png"),
  },
  {
    name: "prima",
    image: require("../assets/sponsors/prima-logo.png"),
  },
  {
    name: "ellies",
    image: require("../assets/sponsors/ellies-logo.png"),
  },
  {
    name: "decomex",
    image: require("../assets/sponsors/decomex-logo.png"),
  },
  {
    name: "brookloon",
    image: require("../assets/sponsors/brook-soon.png"),
  },
  {
    name: "elitex",
    image: require("../assets/sponsors/elitex-soon.png"),
  },
  {
    name: "payaso",
    image: require("../assets/sponsors/payaso-soon.png"),
  },
];
