import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  setDoc,
  collection,
  getDocs,
  getFirestore,
  doc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

const fetchAccessories = async () => {
  const querySnapshot = await getDocs(collection(db, "accessories"));
  return querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });
};

export const useAccessories = () => {
  return useQuery("accessories", fetchAccessories);
};

const fetchAccessoriesByCategory = async (category) => {
  const q = query(
    collection(db, "accessories"),
    where("category", "==", category)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const useAccessoriesByCategory = (category) => {
  return useQuery(
    ["accessoriesCategory", category],
    () => fetchAccessoriesByCategory(category),
    {
      enabled: !!category, // Esto asegura que la consulta solo se ejecute si hay una categoría
    }
  );
};

const addAccessory = async ({ accessory, id }) => {
  await setDoc(doc(db, "accessories", id), accessory);
};

export const useAddAccessories = () => {
  const queryClient = useQueryClient();
  return useMutation(addAccessory, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessories");
    },
  });
};

const updateAccessory = async ({ accessory, accessoryId }) => {
  await setDoc(doc(db, "accessories", accessoryId), accessory);
};

export const useUpdateAccessory = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAccessory, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessories");
    },
  });
};

const deleteAccesory = async (accId) => {
  /* const accToDelete = query(
      collection(db, "accessories"),
      where("id", "==", accId)
    );
  
    const querySnapshot = await getDocs(accToDelete);
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      await deleteDoc(docRef);
    } */

  await deleteDoc(doc(db, "accessories", accId));
};

export const useDeleteAccessory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAccesory, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessories");
    },
    onError: (error) => {
      console.error("Error deleting accessory:", error);
    },
  });
};
