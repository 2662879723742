import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  setDoc,
  collection,
  getDocs,
  getFirestore,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

// ------------------------------- Balloons -------------------------------
// const fetchBalloons = async () => {
//   /* let query = [];
//   let querySnapshot;
//   const querySnapshotCategories = await getDocs(collection(db, "categories"));
//   querySnapshotCategories.docs.map((doc) => {
//     if (!doc.data().value) {
//       query.push(doc.data().name);
//       return querySnapshot = getDocs(collection(db, "balloons"));
//     } else {
//       querySnapshot = getDocs(collection(db, "balloons"));
//       return querySnapshot.docs.map((doc) => doc.data());
//     }
//   }); */
//   const querySnapshot = await getDocs(collection(db, "balloons"));
//   return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
// };

// const addBalloon = async ({ balloon, balloonColor }) => {
//   await setDoc(doc(db, "balloons", balloonColor), balloon);
// };

// const deleteBalloon = async (balloonColor) => {
//   await deleteDoc(doc(db, "balloons", balloonColor));
// };

// export const useBalloons = () => {
//   return useQuery("balloons", fetchBalloons);
// };

// export const useAddBalloon = () => {
//   const queryClient = useQueryClient();
//   return useMutation(addBalloon, {
//     onSuccess: () => {
//       // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
//       queryClient.invalidateQueries("balloons");
//     },
//   });
// };

// export const useDeleteBalloon = () => {
//   const queryClient = useQueryClient();
//   return useMutation(deleteBalloon, {
//     onSuccess: () => {
//       queryClient.invalidateQueries("balloons");
//     },
//     onError: (error) => {
//       console.error("Error deleting balloon:", error);
//     },
//   });
// };

//----------------------------------------------------------------

const fetchBalloonsV2 = async () => {
  const querySnapshot = await getDocs(collection(db, "balloonsv2"));
  return querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });
};

export const useBalloonsV2 = () => {
  return useQuery("balloonsv2", fetchBalloonsV2);
};

const addBalloonV2 = async ({ balloon, balloonColor }) => {
  await setDoc(doc(db, "balloonsv2", balloonColor), balloon);
};

export const useAddBalloonV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(addBalloonV2, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("balloonsv2");
    },
  });
};

const deleteBalloonV2 = async (balloonColor) => {
  await deleteDoc(doc(db, "balloonsv2", balloonColor));
};

export const useDeleteBalloonV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBalloonV2, {
    onSuccess: () => {
      queryClient.invalidateQueries("balloonsv2");
    },
    onError: (error) => {
      console.error("Error deleting balloon:", error);
    },
  });
};
