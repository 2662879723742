import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
//import { MdFavoriteBorder } from "react-icons/md";
//import { BsBalloon } from "react-icons/bs";
import { RiFolderSharedLine } from "react-icons/ri";
import { Button, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { app } from "../../firebase";
import {
  Alert,
  imagesMapToUpload,
  useAddPalette,
  useDeleteUploadedImagesStorage,
  usePaletteById,
  useUpdatePalette,
} from "../../utils";
import { Inputs } from "../index";
import style from "./Commands.module.css";
import { useTranslation } from "react-i18next";

const db = getFirestore(app);

const Save = ({ overlay, setOverlay }) => {
  const { colormatch, images, user } = useAppContext();
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [paletteName, setPaletteName] = useState("");
  const { mutate: addPalette, isLoading } = useAddPalette(false);
  const updatePalette = useUpdatePalette();
  const [loading, setLoading] = useState(false);
  const { paletteId } = useParams();
  const { data: palette, isSuccess } = usePaletteById(paletteId, false, {
    enabled: !!paletteId,
  });
  const { mutate: deleteImagesFromStorage } = useDeleteUploadedImagesStorage();
  const [indexToDelete, setIndexToDelete] = useState([]);
  const [savedPalette, setSavedPalette] = useState(false);
  const { t } = useTranslation();

  const convertURLToFile = async (imageURL, imageName) => {
    const response = await fetch(imageURL);
    const blob = await response.blob();
    const file = new File([blob], imageName, { type: blob.type });
    return file;
  };

  useEffect(() => {
    const paletteFiles = async () => {
      if (paletteId && isSuccess) {
        setPaletteName(palette.name);

        const dbImages = await Promise.all(
          palette.images.map(async (image) => {
            const { imageURL, imageName } = image;
            return await convertURLToFile(imageURL, imageName);
          })
        );

        const indexToDelete = dbImages
          .map((image1, index) =>
            !images.some(
              (image2) =>
                image1.name === image2.name && image1.size === image2.size
            )
              ? index
              : -1
          )
          .filter((index) => index !== -1);
        setIndexToDelete(indexToDelete);
      }
    };
    paletteFiles();
  }, [paletteId, palette, isSuccess, images]);

  const handleSavePalette = useCallback(async () => {
    setLoading(true);
    
    let newId;

    try {
      if (!paletteId) {
        let newDocRef = doc(collection(db, "palettes"));
        newId = newDocRef.id;
        const q = query(
          collection(db, "savedPalettes"),
          where("user", "==", user.email),
          where("name", "==", paletteName)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          throw new Error("There is already a palette with this name");
        }
      }else{
        newId = paletteId;
      }

      // Si no existe una paleta con el mismo nombre, proceder con la subida de imágenes
      const imagesWithURLs = await imagesMapToUpload({
        images,
        setProgress,
        collection: "savedPalettes",
        id: newId,
      });
      const paletteToSave = {
        images: imagesWithURLs,
        colors: colormatch,
        user: user.email,
        name: paletteName,
        createdAt: serverTimestamp(),
      };

      const imagePathsToDelete = ({ images }) => {
        if (Array.isArray(images)) {
          return images.map((image) => {
            return deleteImagesFromStorage({
              imagePath: image.imagePath,
              imageURL: image.imageURL,
            });
          });
        } else if (typeof images === "string") {
          return deleteImagesFromStorage({
            imageURL: images,
          });
        }
      };

      if (paletteId) {
        const imagesToDelete = indexToDelete.map(
          (index) => palette.images[index]
        );
        imagePathsToDelete({
          images: imagesToDelete,
        });
        updatePalette.mutate({
          palette: paletteToSave,
          paletteId: paletteId,
        });
      } else {
        addPalette({ palette: paletteToSave, id: newId });
      }

      Alert.success(
        `Palette ${
          paletteId ? "updated" : "saved"
        }. View it under '${t("Saved Palettes")}' in your profile.`
      );
    } catch (error) {
      Alert.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
      setSavedPalette(true);
      setTimeout(() => {
        setSavedPalette(false);
      }, 500);
    }
  }, [
    paletteId,
    user?.email,
    paletteName,
    images,
    colormatch,
    addPalette,
    updatePalette,
  ]);

  const popover = useMemo(
    () => (
      <Popover id="popover-save">
        <Popover.Body>
          <Row className="justify-content-between">
            <Col xs={8}>
              <Inputs.FormControl
                placeholder={t("Palette title")}
                value={paletteName}
                name="paletteName"
                onChange={(e) => setPaletteName(e.target.value)}
                size="sm"
              />
            </Col>
            <Col xs={4} className={style.shareBtnCol}>
              <Button
                variant="dark"
                onClick={handleSavePalette}
                className={style.shareBtn}
                disabled={paletteName === ""}
              >
                {loading || isLoading ? (
                  "Saving..."
                ) : (
                  <>
                    <RiFolderSharedLine />
                    {t('Save')}
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    ),
    [paletteName, loading, handleSavePalette]
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose
      onExit={() =>
        setOverlay((prevState) => ({
          ...prevState,
          save: false,
        }))
      }
      {...(savedPalette && { show: false })}
    >
      <Button
        variant="secondary"
        className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
        disabled={colormatch.length > 1 ? false : true}
        onClick={() =>
          setOverlay((prevState) => ({
            ...prevState,
            save: true,
          }))
        }
      >
        <RiFolderSharedLine />
        {paletteId ? t('Save') : t('Save')}
      </Button>
    </OverlayTrigger>
  );
};

export default Save;
