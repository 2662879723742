import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const FormControl = ({
  label,
  name,
  onChange,
  value,
  feedback,
  icon,
  decoration,
  ...rest
}) => {
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <InputGroup>
        {icon && <InputGroup.Text>{icon}</InputGroup.Text>}
        <Form.Control onChange={onChange} name={name} value={value} {...rest} />
        {feedback && (
          <Form.Control.Feedback type={feedback.type}>
            {feedback.message}
          </Form.Control.Feedback>
        )}
        {decoration && value && <InputGroup.Text>{decoration}</InputGroup.Text>}
      </InputGroup>
    </Form.Group>
  );
};

export default FormControl;
