import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "path-browserify";
import { AppWrapper } from "./context";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppRoutes } from "./Routes";
import "./utils/language/i18n";

// Crea una instancia de QueryClient
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <AppWrapper>
      <AppRoutes />
    </AppWrapper>
  </QueryClientProvider>
);

serviceWorkerRegistration.register();
