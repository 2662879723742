import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Prod
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
  authDomain: "paris312.firebaseapp.com",
  projectId: "paris312",
  storageBucket: "paris312.appspot.com",
  messagingSenderId: "1052435946542",
  appId: "1:1052435946542:web:b85d37cf6e35ab5a7653bf",
  measurementId: "G-49EMCJ5XNK",
};

// Dev
/* const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
  authDomain: "paris312-colormatch-dev.firebaseapp.com",
  projectId: "paris312-colormatch-dev",
  storageBucket: "paris312-colormatch-dev.appspot.com",
  messagingSenderId: "140406262197",
  appId: "1:140406262197:web:a5e083e6282cb66161f1fc",
}; */

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { app, storage };
