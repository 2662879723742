import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Categories } from "./index";
import style from "../Dashboard.module.css";

const Settings = () => {
  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>Settings</h1>
        </Col>
      </Row>
      <Row className={`gap-5 ${style.body}`}>
        <Col sm={12} md={6} lg={3}>
          <Card className={style.cardContainer}>
            <Categories />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
