import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  collection,
  getDocs,
  getFirestore,
  doc,
  writeBatch,
} from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

const fetchCategories = async () => {
  const querySnapshot = await getDocs(collection(db, "categories"));
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const useCategories = () => {
  return useQuery("categories", fetchCategories);
};

const setCategories = async (updatedData) => {
  const batch = writeBatch(db);
  for (const property in updatedData) {
    const docRef = doc(db, "categories", property);
    batch.update(docRef, { value: updatedData[property].value });
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log(error);
  }
};

export const useSetCategories = () => {
  const queryClient = useQueryClient();
  return useMutation(setCategories, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("categories");
    },
  });
};
