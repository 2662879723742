import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  updateDoc,
  collection,
  getDocs,
  getFirestore,
  doc,
  writeBatch,
} from "firebase/firestore";
import { app } from "../../firebase";

const db = getFirestore(app);

const fetchUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "usuarios"));
  return querySnapshot.docs.map((doc) => doc.data());
};

export const useUsers = () => {
  return useQuery("usuarios", fetchUsers);
};

const updateUsers = async (updatedData) => {
  const batch = writeBatch(db);
  for (const property in updatedData) {
    const docRef = doc(db, "usuarios", property);
    batch.update(docRef, { role: updatedData[property].role });
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log(error);
  }
};

export const useUpdateUsers = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};

const updateUser = async ({ user, userId }) => {
  await updateDoc(doc(db, "usuarios", userId), user);
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};
