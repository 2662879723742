import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../index.css";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const userLoggedIn = localStorage.getItem("userLoggedIn");

  useEffect(() => {
    if (!userLoggedIn) {
      return navigate("login");
    }
  }, [userLoggedIn, navigate]);

  return <Outlet />;
};

export default ProtectedRoutes;
