import { useState, useMemo, useCallback } from "react";
import { brands } from "../index";

const useFilterByBrand = (data) => {
  const [brandsSelected, setBrandsSelected] = useState(() =>
    Object.fromEntries(brands.map((brand) => [brand, true]))
  );

  const handleBrandsChange = useCallback((brand) => {
    setBrandsSelected((prevState) => ({
      ...prevState,
      [brand]: !prevState[brand],
    }));
  }, []);

  const filteredBrands = useMemo(() => {
    const brandsArray = Object.values(brandsSelected);

    // Caso 1: Si todas las marcas están en `true`, devuelve `data` sin filtrar.
    if (brandsArray.every((isSelected) => isSelected)) {
      return data;
    }

    // Caso 2: Si todas las marcas están en `false`, devuelve un array vacío.
    if (brandsArray.every((isSelected) => !isSelected)) {
      return [];
    }

    // Caso 3: Si hay algunas marcas en `true` y otras en `false`, filtra `data`.
    const brandsToExclude = new Set(
      Object.keys(brandsSelected).filter((brand) => !brandsSelected[brand])
    );

    return data?.filter((balloon) => {
      const balloonBrandsSet = new Set(
        balloon.brand.split("/").map((b) => b.trim())
      );
      return ![...balloonBrandsSet].some((brand) => brandsToExclude.has(brand));
    });
  }, [brandsSelected, data]);

  return {
    brandsSelected,
    handleBrandsChange,
    filteredBrands,
  };
};

export default useFilterByBrand;
