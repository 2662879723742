import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { SideBar } from "../components";
import "../index.css";

const AdminRoutes = () => {
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole !== "1") {
      navigate("/");
    }
  }, [userRole, navigate]);

  return (
    <Container fluid className={`m-0 p-0 fluid_admin_routes`}>
      <SideBar />
      <Container>
        <Outlet />
      </Container>
    </Container>
  );
};

export default AdminRoutes;
