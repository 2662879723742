import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { VscDiffAdded } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useAppContext } from "../../context";
import {
  AddElementModalContent,
  CustomModalContent,
  DropdownBtn,
  Filters,
  ModalWindow,
  useAddBtnModalState,
} from "../index";
import {
  Alert,
  colorMatchFn,
  useAccessoriesByCategory,
  useBalloonsV2,
  useScreenSize,
} from "../../utils";
import style from "./Add.module.css";

const AddButton = () => {
  const { colormatch, setColormatch, defaultModal } = useAppContext();
  const [category, setCategory] = useState("");
  const { data: balloons, isLoading: loadingBalloons } = useBalloonsV2();
  const { data: accessoryCategory, isLoading: loadingAccessories } =
    useAccessoriesByCategory(category);
  const { modalState, showModal, closeModal } = useAddBtnModalState();
  const [selected, setSelected] = useState([]);
  const [customImg, setCustomImage] = useState({
    imageURL: null,
    name: "",
  });

  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const handleColorMatch = useCallback(
    (elementSelected) => {
      colorMatchFn(
        balloons,
        loadingBalloons,
        colormatch,
        setColormatch,
        elementSelected
      );
    },
    [balloons, loadingBalloons, colormatch, setColormatch]
  );

  const dropdownOptions = useMemo(
    () => [
      { title: t("Latex Balloons"), action: () => showModal("balloon") },
      {
        title: t("Foil Shape Balloons"),
        action: () => {
          showModal("foilShape");
          setCategory("Foil Shape");
        },
      },
      {
        title: t("Foil Number & Letter Balloons"),
        action: () => {
          showModal("foilNumberLetter");
          setCategory("Foil Number & Letter");
        },
      },
      {
        title: t("Confetti"),
        action: () => {
          showModal("confetti");
          setCategory("Confetti");
        },
      },
      {
        title: t("Tassels"),
        action: () => {
          showModal("tassel");
          setCategory("Tassels");
        },
      },
      {
        title: t("Backdrop/Panel Colors"),
        action: () => {
          showModal("backdropPanel");
          setCategory("Backdrop/Panel Color");
        },
      },
      {
        title: t("Greenery & Florals"),
        action: () => {
          showModal("floralGreenery");
          setCategory("Floral & Greenery");
        },
      },
      {
        title: t("Custom Upload"),
        action: () => showModal("custom"),
      },
    ],
    [showModal]
  );

  const dropdownOptionsRef = useRef(dropdownOptions);
  useEffect(() => {
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions]);

  useEffect(() => {
    if (defaultModal) {
      const option = dropdownOptionsRef.current.find(
        (option) => option.title === defaultModal
      );
      if (option) {
        option.action();
      }
    }
  }, [defaultModal]);

  const handleAdd = () => {
    if (modalState.balloon) {
      selected.forEach((item) => handleColorMatch(item.color));
    } else if (modalState.custom) {
      if (customImg.name === "") return Alert.error("Please provide a title.");
      setColormatch((prevState) => [...prevState, customImg]);
    } else {
      const accessoriesSelected = selected.map((item) => ({
        id: item.id,
        name: item.name,
        hexColor: item.hexColor,
        imageURL: item.imageURL,
      }));
      setColormatch((prevState) => [...prevState, ...accessoriesSelected]);
    }
    setSelected([]);
    setCustomImage({
      imageURL: null,
      name: "",
      custom: true,
    });
    closeModal();
  };

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    rejectedFiles.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          Alert.error(
            "Format not accepted. Accepts .jpg, .jpeg, .png, .webp, and .gif file types."
          );
        }
      });
    });
    const imgSrc = URL.createObjectURL(acceptedFiles[0]);
    setCustomImage({ ...customImg, imageURL: imgSrc });
  };

  const renderModal = () => {
    if (modalState.balloon) {
      return (
        <ModalWindow
          className={style.modalFullscreenCustom}
          show={modalState.balloon}
          onHide={closeModal}
          title={t("Latex Balloons")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={<Filters selected={selected} setSelected={setSelected} />}
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.foilShape) {
      return (
        <ModalWindow
          show={modalState.foilShape}
          onHide={closeModal}
          title={t("Foil Shape Balloons")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <AddElementModalContent
              title={t("Foil Shape Balloons")}
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={t("Search...")}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  {/* <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col> */}
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.foilNumberLetter) {
      return (
        <ModalWindow
          show={modalState.foilNumberLetter}
          onHide={closeModal}
          title={t("Foil Number & Letter Balloons")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <AddElementModalContent
              title={t("Foil Number & Letter Balloons")}
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={t("Search...")}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  {/* <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col> */}
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.confetti) {
      return (
        <ModalWindow
          show={modalState.confetti}
          onHide={closeModal}
          title="Confetti"
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <AddElementModalContent
              title="Confetti"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={t("Search...")}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  {/* <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col> */}
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.tassel) {
      return (
        <ModalWindow
          show={modalState.tassel}
          onHide={closeModal}
          title={t("Tassels")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <AddElementModalContent
              title={t("Tassels")}
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={t("Search...")}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  {/* <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col> */}
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.backdropPanel) {
      return (
        <ModalWindow
          show={modalState.backdropPanel}
          onHide={closeModal}
          title={t("Backdrop/Panel Colors")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <AddElementModalContent
              title={t("Backdrop/Panel Colors")}
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={t("Search...")}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  {/* <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col> */}
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.floralGreenery) {
      return (
        <ModalWindow
          show={modalState.floralGreenery}
          onHide={closeModal}
          title={t("Greenery & Florals")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <AddElementModalContent
              title={t("Greenery & Florals")}
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={t("Search...")}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  {/* <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col> */}
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else if (modalState.custom) {
      return (
        <ModalWindow
          show={modalState.custom}
          onHide={closeModal}
          title={t("Custom Upload")}
          closeButton={true}
          size="xl"
          fullscreen={screenSize < 992 && true}
          body={
            <CustomModalContent
              customImg={customImg}
              handleDrop={handleDrop}
              setCustomImage={setCustomImage}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              {t("Add to Palette")}
            </Button>
          }
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <DropdownBtn
        variant="dark"
        id="dropdown-basic"
        title={
          <>
            <VscDiffAdded /> {t("Add from Library")}...
          </>
        }
      >
        {dropdownOptions.map((option, index) => (
          <Dropdown.Item onClick={option.action} key={index}>
            {option.title}
          </Dropdown.Item>
        ))}
      </DropdownBtn>
      {renderModal()}
    </Fragment>
  );
};

export default AddButton;
