import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import { Footer } from "../../components";
import { privacyEn } from "./privacyPolicyEn";
import { privacyEs } from "./privacyPolicyEs";

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <Container className="my-3">
        {i18n.language === "en"
          ? privacyEn.map((p, index) => (
              <Row className="p-0 m-0" key={index}>
                <Col>
                  {p.title && (
                    <p>
                      <b>{p.title}</b>
                    </p>
                  )}
                  {p.text.split("\n").map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </Col>
              </Row>
            ))
          : privacyEs.map((p, index) => (
              <Row className="p-0 m-0" key={index}>
                <Col>
                  {p.title && (
                    <p>
                      <b>{p.title}</b>
                    </p>
                  )}
                  {p.text.split("\n").map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
                </Col>
              </Row>
            ))}
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
