import React from "react";
import { Dropdown } from "react-bootstrap";
import style from "./DropdownBtn.module.css";

const DropdownBtn = ({ variant, id, title, disabled, children }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id={id}
        variant={variant}
        disabled={disabled}
        className={style.actionBtn}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu className={style.dropdownMenu}>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownBtn;
