import { NavLink, useParams } from "react-router-dom";

const NavLinkWithLocale = ({ to, children, ...props }) => {
  const { lang } = useParams();
  
  // Asegúrate de que `lang` tenga un valor predeterminado si es `undefined`
  const locale = lang || 'en'; // `en` es el idioma por defecto

  return (
    <NavLink to={`/${locale}${to}`} {...props}>
      {children}
    </NavLink>
  );
};

export default NavLinkWithLocale;