import React from 'react';
import {Container, Spinner, Row, Col, Button} from "react-bootstrap";
import axios from 'axios';

const Landing = ()=>{

  const handleSubscribe = async()=>{
    axios.post('http://localhost:4242/create-checkout-session', {
      lookup_key: "color_match",
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(function (response) {
      window.location.href = response.data.url;
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return(
    <Container>
      <Row>
        <Col>
          <h1 className='mt-4 mb-4'>Landing page</h1>
          <p className='mb-4'><strong>Step 1/2:</strong> Click the button below to subscribe to Color Match Tool</p>
          <Button id="checkout-and-portal-button" type="button" onClick={handleSubscribe}>
            Subscribe to Color Match Tool
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default Landing;