import React from "react";
import style from "./HamburguerBtn.module.css";

const HamburguerBtn = ({ clicked, handleOffcanvas }) => {
  return (
    <div
      className={`${style.burguerMenu}`}
      onClick={handleOffcanvas}
      role="button"
      aria-label="Toggle menu"
      aria-expanded={clicked}
    >
      <div className={`${style.burguer} ${clicked && style.clicked}`} />
      <div className={`${style.burguer} ${clicked && style.clicked}`} />
      <div className={`${style.burguer} ${clicked && style.clicked}`} />
    </div>
  );
};

export default HamburguerBtn;
