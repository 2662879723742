import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Row, Col, Navbar, Container } from "react-bootstrap";
import style from "./Footer.module.css";
import { NavLinkWithLocale } from "../index";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Navbar className={`${style.footer}`}>
      <Container>
        <Row className={`p-0 m-0 align-items-center ${style.container}`}>
          <Col xs={12} md={6} xl={5} className={style.firstHalf}>
            <NavLinkWithLocale to="/terms" className={style.links}>
              {t("Terms")}
            </NavLinkWithLocale>
            <NavLinkWithLocale to="/privacyPolicy" className={style.links}>
              {t("Privacy Policy")}
            </NavLinkWithLocale>
            <NavLinkWithLocale to="/contactUs" className={style.links}>
              {t("Contact Us")}
            </NavLinkWithLocale>
            {/* <LanguageSwitcher className={style.links} drop="up" position="footer" /> */}
          </Col>
          <Col xs={12} md={{ offset: 1, span: 5 }} xl={{ offset: 3, span: 4 }}>
            <Row className="align-items-start">
              <Col xs={9} className={style.copyright}>
                <p className="m-0">
                  {t("© 2024 Balloon Color Match by Paris312.")}
                  <br /> {t("All rights reserved.")}
                </p>
              </Col>
              <Col xs={3} className={style.media}>
                <a
                  href="https://instagram.com/ballooncolormatch"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaInstagram
                    color="whitesmoke"
                    className={style.mediaIcons}
                  />
                </a>
                <a
                  href="https://www.facebook.com/BalloonColorMatch"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaFacebook color="whitesmoke" className={style.mediaIcons} />
                </a>
                {/* <a
                  href="https://www.tiktok.com/@ballooncolormatch"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaTiktok color="whitesmoke" className={style.mediaIcons} />
                </a> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
